<template>
  <div class="insight-selection-container align-items-center">
    <insight-sorter :insight-list="currentInsights" v-if="insightSorterVisible" @insightSortingComplete="insightsSorted" @insightSortingCancelled="insightSortingCancelled"/>
      <div v-if="!createInsightMode" class="d-flex flex-row align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center w-100">
          <h3 class="insight">Insight</h3>
          <b-dropdown v-if="showInsights" size="sm" variant="outline-primary bg-white border-white text-dark" :class="['mx-2', 'insight-dropdown']">
            <template #button-content>
              <div :class="['dd']">
                <p>{{ currentInsight ? currentInsight.title : '' }}</p>
              </div>
            </template>
            <b-dropdown-item-button
              class="dropdown-button insight-dropdown-item"
              variant="dark"
              v-for="(insight, i) in currentInsights"
              :key="insight.id"
              @click="setCurrentInsightIndex(i)">
              <b-icon v-if="isInsightDraft(insight)" variant="warning" icon="dot"></b-icon>
              <b-icon v-else variant="success" icon="dot"></b-icon>
              {{ insight.title }}
            </b-dropdown-item-button>
            <b-dropdown-divider v-if="userCanAuthorInsights"></b-dropdown-divider>
            <b-dropdown-item-button class="dropdown-button" @click="createNewInsight" v-if="userCanAuthorInsights">+ Add Insight</b-dropdown-item-button>
            <b-dropdown-item-button class="dropdown-button" @click="toggleInsightSorter" v-if="userCanAuthorInsights && (currentInsights.length > 1)"><b-icon-arrow-down-up /> Sort Insights</b-dropdown-item-button>
            <b-dropdown-item-button class="dropdown-button" @click="exportInsightsPdf" v-if="userCanAuthorInsights && companyCanExportPDF"><b-icon-arrow-down-circle /> Export Insights PDF</b-dropdown-item-button>
          </b-dropdown>
          
          <b-button v-if="!showInsights && userCanAuthorInsights" variant=primary class="ml-4 btn-side-paddings" @click="createNewInsight">+ Add Insight</b-button>
          <div v-if="this.currentInsights.length > 1" class="insight-nav-btns">
            <b-icon-arrow-left-circle v-if="this.currentInsightIndex <= 0 || editInsightMode" class="insight-nav-btn-disabled" />
            <b-icon-arrow-left-circle v-else @click="currentInsightIndex--" class="insight-nav-btn" />
            <b-icon-arrow-right-circle v-if="(currentInsights != null && this.currentInsightIndex >= this.currentInsights.length - 1) || editInsightMode" class="insight-nav-btn-disabled" />
            <b-icon-arrow-right-circle v-else @click="currentInsightIndex++" class="insight-nav-btn" />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-4 mr-3" v-if="currentInsight">
          <div v-b-tooltip.hover
            :title="drawingMode ? 'cannot edit insights will using the drawing tool' : 'edit insight'">
            <b-button 
              variant="outline-white border-transparent text-dark"
              class="insight-title-bar-button p-1 mr-1 gray-button" 
              @click="editInsight" 
              :disabled='drawingMode' 
              v-if="userCanAuthorInsights && !editInsightMode && !createInsightMode">
              <b-icon-pencil-square />
            </b-button>
          </div>
          <!-- <b-button title="cancel" class="insight-title-bar-button p-1 mr-2" @click="cancelEditMode" v-if="userCanAuthorInsights && editInsightMode && !createInsightMode">
            <b-icon-x-circle />
          </b-button> -->
          <b-button 
            v-b-tooltip.hover 
            title="delete insight" 
            variant="danger text-dark" 
            class="insight-title-bar-button p-1 mr-1" 
            v-b-modal.confirm-insight-delete
            v-if="userCanAuthorInsights && editInsightMode">
            <b-icon-trash></b-icon-trash>
          </b-button>
          <b-button 
            v-b-tooltip.hover 
            title="close edit mode" 
            variant="outline-primary bg-white border-white text-dark"
            class="insight-title-bar-button p-1 mr-1" 
            @click="saveInsight" 
            v-if="userCanAuthorInsights && editInsightMode">
            <b-spinner v-if="updatingInsightResource" small variant="dark-text"></b-spinner>
            <img v-else src="~@/assets/svg/close-edit-icon.svg" height="18" alt="save" />
          </b-button>
          <b-button 
            id="toggle-geometry"
            variant="outline-white border-transparent text-dark"
            class="insight-title-bar-button p-1 mr-1 gray-button" 
            @click="toggleGeometryPicker"
            v-if="isSceneInsight">
            <img src="~@/assets/img/Light.png" height="18" alt="save" />
          </b-button>
          <b-tooltip v-if="!geometryPickerOpen" target="toggle-geometry" triggers="hover">
            Toggle geometry
          </b-tooltip>
          <div ref="geometryPicker" tabindex="0" @blur="onBlur" v-show="geometryPickerOpen" class="layers-picker">
            <div v-if="insightGeometryLayerSets && insightGeometryLayerSets.length > 0" class="layers-group">
              <input-button v-for="(layer, index) in this.insightGeometryLayerSets"
              theme='checkbox'
              :key="`${layer.identifier}-${index}`"
              :layersetAssets="layer.layerSetAssets"
              :layer="layer"
              :uuid="`${layer.identifier}-${index}-${rank}`"
              :isDisabled="layer.isLoading"
              :isLoading="layer.isLoading"
              :isVisible="layer.isVisible"
              :hideDeleteButton="true"
              :complete-label="layer.completeLabel"
              :abbreviated-label="layer.abbreviatedLabel"
              @toggle="toggleGeometryLayerSet(layer)"
              @toggleWireframe="toggleWireframe"/>
            </div>
            <p class="no-available" v-else>No geometry layers available</p>
          </div>
          <b-button
            id="toggle-layers"
            variant="outline-white border-transparent text-dark"
            class="insight-title-bar-button p-1 gray-button" 
            @click="toggleLayersPicker"
            v-if="isSceneInsight">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.49996 10.1738C7.72897 10.1739 6.95823 9.9911 6.25541 9.62567L1.18756 6.9903C0.452672 6.60819 -0.00228727 5.86923 0.000100887 5.06182C0.00252088 4.25442 0.461907 3.51805 1.19905 3.14001L6.27968 0.534683C7.66996 -0.178228 9.32996 -0.178228 10.7202 0.534683L15.8009 3.14001C16.538 3.51802 16.9974 4.25439 16.9998 5.06182C17.0022 5.86923 16.5472 6.60819 15.8124 6.9903L10.7445 9.62567C10.0419 9.9911 9.27079 10.1738 8.49996 10.1738ZM8.49996 1.28769C7.95065 1.28769 7.40138 1.41608 6.90066 1.67286L1.8201 4.27822C1.51314 4.43563 1.32925 4.73 1.32827 5.06559C1.32728 5.40118 1.50935 5.69657 1.81539 5.8557L6.88327 8.49104C7.89569 9.0175 9.10442 9.0175 10.1168 8.49104L15.1847 5.8557C15.4908 5.69657 15.6728 5.40121 15.6718 5.06559C15.6709 4.72997 15.487 4.43563 15.18 4.27822L10.0993 1.67286C9.59854 1.41611 9.04927 1.28769 8.49996 1.28769ZM10.7445 15.4519L16.6497 12.3812C16.9729 12.2132 17.0944 11.8229 16.921 11.5096C16.7477 11.1963 16.3451 11.0785 16.0219 11.2466L10.1167 14.3172C9.10442 14.8437 7.89566 14.8437 6.88066 14.3159L0.975487 11.2775C0.651462 11.1108 0.24952 11.2303 0.0776363 11.5443C-0.0943109 11.8584 0.028918 12.248 0.352879 12.4147L6.25544 15.4518C6.95826 15.8173 7.729 16 8.49999 16C9.27079 16 10.0419 15.8173 10.7445 15.4519ZM10.7445 12.5549L16.6497 9.48425C16.9729 9.3162 17.0944 8.92594 16.921 8.61264C16.7477 8.29928 16.3451 8.18157 16.0219 8.3496L10.1167 11.4202C9.10442 11.9467 7.89566 11.9467 6.88066 11.4189L0.975487 8.38046C0.651462 8.21377 0.24952 8.33327 0.0776363 8.64731C-0.0943109 8.96138 0.028918 9.35105 0.352879 9.51775L6.25544 12.5549C6.95826 12.9203 7.729 13.103 8.49999 13.103C9.27079 13.103 10.0419 12.9203 10.7445 12.5549Z" fill="black"/>
            </svg>
          </b-button>
          <b-tooltip v-if="!layersPickerOpen" target="toggle-layers" triggers="hover">
            Toggle layers
          </b-tooltip>
          <div ref="layersPicker" tabindex="0" @blur="onBlur" v-show="layersPickerOpen" class="layers-picker">
            <div v-if="insightLayerSets && insightLayerSets.length > 0" class="layers-group">
              <input-button v-for="(layer, index) in insightLayerSets"
                theme='hybrid-radio-checkbox'
                :key="layer.identifier"
                :layersetAssets="layer.layerSetAssets"
                :layer="layer"
                :uuid="`${layer.identifier}-${index}`"
                :isDisabled="resultLayerOfTypeLoading(layer.layerType)"
                :isLoading="layer.isLoading"
                :isVisible="layer.isVisible"
                :hideDeleteButton="true"
                :complete-label="layer.completeLabel"
                :abbreviated-label="layer.abbreviatedLabel"
                @toggle="toggleInsightsLayerSet(layer)"
              />
            </div>
            <p class="no-available" v-else>No insight layers available</p>
          </div>
        </div>
      </div>
      <h3 class="insight" v-else>New Insight</h3>
      <div class="delete-insight-container" v-if="viewerMode === 'Insights' && userCanAuthorInsights && editInsightMode && currentInsight && !sceneViewCompositionMode">
        <b-modal id="confirm-insight-delete" centered hide-header-close title="Are you sure?" >
          <p>Are you sure you wish to delete the insight: {{ this.currentInsight.title }}?</p>
          <template #modal-footer>
            <b-button @click='$bvModal.hide("confirm-insight-delete")' class='float-left btn-side-paddings'>Cancel</b-button>
            <b-button @click='deleteCurrentInsight' variant='danger' class='float-right btn-side-paddings'>Delete</b-button>
          </template>
        </b-modal>        
      </div>
    </div>
</template>

<script>
import { AUTHOR_INSIGHTS } from '@/constants/permissions';
import InputButton from '../viewer/InputButton';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '@/network/eventbus';
import projectApiService from '@/network/project';
import InsightSorter from '../viewer/InsightSorter';
import _ from 'lodash';
export default {
  name: 'InsightsTitle',
  components: {
    InputButton,
    InsightSorter
  },
  props: {
    rank: {
      type: String,
      required: true
    },
    geometryLayerSets: {
      required: true
    },
  },
  data() {
    return {
      layerSets: [],   // layer sets from the simulationasset store that were selected in the layer picker (or added at load via the 'show_by_default' flag)
      geometryLayers: [],  //assets of type 'geometry' from the store, grouped into layersets (though there is only 1 asset in each layerset)
      newInsightDescription: null,
      newInsightTitle: null,
      insightSorterVisible: false,
      insightCopyBeforeEdit: null,
      currentInsightIndex: 0,
      updatingInsightResource: false,
      geometryPickerOpen: false,
      insightLayerSets: [],  //layerSets associated with the currently active Insight
      layersPickerOpen: false
    };
  },
  async mounted() {
    if (this.$route.query.page)
      this.currentInsightIndex = Number(this.$route.query.page);
    this.$root.$on('finishedUpdatingInsight', () => {
      this.finishedUpdatingInsight();
    });

    this.$root.$on('goToLastInsightIfIndexTooLarge', () => {
      this.goToLastInsightIfIndexTooLarge();
    });

    this.$root.$on('goToPreviousInsightIfExists', () => {
      this.goToPreviousInsightIfExists();
    });

    this.$root.$on('goToLastInsight', () => {
      this.goToLastInsight();
    });

    this.$root.$on('editInsight', () => {
      this.editInsight();
    });
  },
  computed: {
    isSceneInsight() {
      return this.currentInsight.insight_type == 'Scene';
    },
    /* this needs to be a computed property to make sure the first Insight loads correctly.  When the first Insight is loaded, the API call to load assets has almost certainly
    not returned yet so we can't pull out the associated assets into the layerSets that are associated with the Insight.  By having this as a calculated prop, as soon as allLayersetsForSelectedInsightSimulation
    has loaded, this will be re-calculated and we can render the layers.  A watch on this array handles setting the copy of the array from 'data' when this changes
    */
    insightLayers() {
      const activeLayers = this.firstSceneView?.active_layers;  //active layers is a list of assets that should be displayed with the Insight
      if (activeLayers && this.allLayersetsForSelectedInsightSimulation.length > 0) {  //allLayersetsForSelectedInsightSimulation is all of the assets associated with the currentInsight's configuration, grouped into LayerSets
        let layerSetsForCurrentInsight = [];
        for (let i = 0; i < activeLayers.length; i++) {
          let layerSetForAsset = this.allLayersetsForSelectedInsightSimulation.find(layer => layer.layerSetAssets.some(asset => asset.simulationResult.id == activeLayers[i].id));
          if (layerSetForAsset == undefined) {
            //2 things cause this computed to trigger and recalculate, and update to this.firstSceneView and and update to this.allLayersetsForSelectedInsightSimulation.
            //the it first triggers for a change the firstSceneView then the active_alyers of the firstSceneView won't be found in the allLayersetsForSelectedInsightSimulation collection.
            //it will, however, trigger immediately again afterwards and find the layersets it needs, updating the computed properly
            return [];
          }
          //layerSetForAsset might already be in layerSetsForCurrentInsight, if this activeLayer (which is actually an asset, not a layer) is a different presentation plane of a previous result layer
          //any assets that are in the `layerSetForAsset` and `activeLayers` should be visible and layers in `layerSetForAsset` that aren't in `activeLayers` should not be visible
          let existingEntry = layerSetsForCurrentInsight.find(layer => layer.identifier == layerSetForAsset.identifier);
          if (!existingEntry) {
            layerSetsForCurrentInsight = layerSetsForCurrentInsight.concat(layerSetForAsset);
          } 
        }

        //turn off any layers visibly that aren't part of the insight
        for(let layer of layerSetsForCurrentInsight) {
          for (let asset of layer.layerSetAssets) {
            if (!activeLayers.some(x => x.id == asset.simulationResult.id)) {
              asset.isVisible = false;
            } else {
              asset.isVisible = true;
            }
          }
        }

        return layerSetsForCurrentInsight;
      }
      return [];
    },
    showInsights() {
      return this.currentInsights.length > 0;
    },
    currentInsight() {
      if (this.currentInsights && this.currentInsightIndex > this.currentInsights.length - 1) {
        return this.currentInsights.at(this.currentInsights.length - 1);
      }
      return this.currentInsights?.at(this.currentInsightIndex);
    },
    userCanAuthorInsights() {
      return (this.$store.getters['allowedPermissions'] || {})[AUTHOR_INSIGHTS];
    },
    companyCanExportPDF() {
      const companyHasModule = this.userCompany?.modules.find(mod => mod.name.toLowerCase() === 'pdf export');
      return companyHasModule || this.loggedInUser?.is_superuser;
    },
    showInsightLayersToggle() {
      return this.viewerMode === 'Insights' && this.insightLayerSets.length > 0 && !this.createInsightMode;
    },
    //union all insight layers with layers of a specific layer_type to faciliate radio toggling
    getInsightAllLayersAndResultLayersOfType(layerType) {
      return this.insightLayerSets.concat(this.layerSets.filter(layer => layer.layerType == layerType));
    },
    currentInsightIsSceneView() {
      return this.currentInsight?.sceneview_set.length > 0;
    },
    currentInsightIsReportView() {
      return this.currentInsight?.insight_type == 'Report';
    },
    ...mapGetters('project', ['selectedStudy', 'firstSceneView', 'drawingMode', 'currentInsights', 'createInsightMode', 'sceneViewCompositionMode', 'editInsightMode', 'viewerMode']),
    ...mapGetters('project/simulationAsset', ['addedLayerSets', 'defaultAssets', 'allLayersetsForSelectedInsightSimulation', 'showByDefaultLayerSets', 'insightGeometryLayerSets', 'insightGeometryAssets', 'simulationsFinishedLoading', 'firstSimulationFinishedLoading']),
    ...mapGetters('project/viewer', ['savedLayers']),
    ...mapGetters(['userCompany', 'loggedInUser'])
  },
  methods: {
    /* turns an Insights layer on/off.  Multiple Insights layers can be active at once but turning any of them on should turn off all regular layers */
    toggleInsightsLayerSet(layer) {
      if (layer.isVisible) {
        //layer was visisble and needs to be turned off
        this.toggle(null, [layer]);
      } else {
        //layer wasn't visible and needs to be turned on.  all normal result layers should be turned off
        this.toggle([layer], this.layerSets);
      }

      if (this.layersPickerOpen === true)
        this.$nextTick(() => this.$refs.layersPicker.focus());
    },
    onBlur(event) {
      // Vue.nextTick to wait for the DOM to update
      this.$nextTick(() => {
        if (event.relatedTarget) {
          var targetTag = event.relatedTarget.tagName;
          // Perform actions based on the tag
          if (targetTag === 'INPUT' || targetTag === 'BUTTON') {
            this.$refs.geometryPicker.focus();
          }
        }
      });
      if (this.geometryPickerOpen == true) {
        if (event.relatedTarget == null)
          this.geometryPickerOpen = false;
        if (event.relatedTarget && event.relatedTarget.id === '' && event.relatedTarget.parentNode.id === '')
          this.geometryPickerOpen = false;
        if (event.relatedTarget?.className == 'dropdown-menu show')
          this.geometryPickerOpen = false;
      } else if (this.layersPickerOpen == true) {
        if (event.relatedTarget == null)
          this.layersPickerOpen = false;
        if (event.relatedTarget && event.relatedTarget.id === '' && event.relatedTarget.parentNode.id === '')
          this.layersPickerOpen = false;
        if (event.relatedTarget?.className == 'dropdown-menu show')
          this.geometryPickerOpen = false;
      }
    },
    toggleWireframe(layer) {
      this.$root.$emit('toggleWireframeInsight', layer.identifier);
    },
    /* formats data for the viewer store's ToggleLayer action */
    createAssetToggleInformation(layerToToggle, toggleOn) {
      return {
        layerToToggle: layerToToggle,
        toggleOn: toggleOn
      };
    },
    /* utility method to toggle a set of layers on or off.  sets IsVisible on the layer and notifies the viewer to show/hide the layers */
    toggle(layersOn, layersOff) {
      const toggleArray = [];

      //process layersOff first in case a layer is being turned off and on again in the same call (this happens in the radio toggle)
      if (layersOff) {
        layersOff.forEach(layer => {
          layer.isVisible = false;
          toggleArray.push(this.createAssetToggleInformation(layer, false));
        });
      }

      if (layersOn) {
        layersOn.forEach(layer => {
          layer.isVisible = true;
          toggleArray.push(this.createAssetToggleInformation(layer, true));
        });
      }
      if (toggleArray.length > 0) {
        this.handleInsightLayersList(toggleArray);
        this.toggleLayers(toggleArray);
      }
    },
    handleInsightLayersList(toggleArray){
      this.$emit('insightLayersPresent', toggleArray);
    },
    /* toggle on/off a geometry layer */
    toggleGeometryLayerSet(layer) {
      if (layer.isVisible) {
        //layer was visisble and needs to be turned off
        this.toggle(null, [layer]);
      } else {
        //layer wasn't visible and needs to be turned on
        this.toggle([layer], null);
      }

      if (this.geometryPickerOpen === true)
        this.$nextTick(() => this.$refs.geometryPicker.focus());
    },
    /* load any saved geometry layers.  If none exist, load the default layers */
    loadDefaultOrSavedGeometryLayers(defaultLayers) {
      var toggleOff = [].concat(this.geometryLayers);
      var toggleOn = [];

      const savedLayersKey = `project:${this.projectId},study:${this.studyId},simulation:${this.simulationId}`;
      const projectSavedLayers = this.savedLayers[savedLayersKey];

      if (projectSavedLayers) {  //load the saved geometry layers into the layer panel.
        this.geometryLayers = projectSavedLayers.geometryLayers;
        if (this.geometryLayers && this.geometryLayers.length > 0) {
          this.geometryLayers.filter(layer => layer.isVisible).forEach(layer => {
            toggleOn.push(layer);  //turn on anything marked as isVisible == true
          });
        }
      } else {  //load the default geometry layers into the layer panel.
        this.geometryLayers = defaultLayers;
        if (this.isPreviewModeOpen) {
          //when in scenario preview mode, show all geometry assets by default
          this.geometryLayers.forEach(layer => {
            toggleOn.push(layer);
          });
        } else {
          //not confitming a new scenario so toggle anything marked as a default layer
          this.geometryLayers.forEach(layer => {  //turn on anything marked as 'Show by default"
            if (layer.showByDefault === true) {
              toggleOn.push(layer);
            }

          });
        }
      }
      // delay toggling layers since this occurs at the same time as the result layers are loaded
      setTimeout(() => {
        this.toggle(toggleOn, toggleOff);
      }, 0);
    },
    toggleGeometryPicker() {
      this.layersPickerOpen = false;
      this.geometryPickerOpen = !this.geometryPickerOpen;
      if (this.geometryPickerOpen === true)
        this.$nextTick(() => this.$refs.geometryPicker.focus());
    },
    toggleLayersPicker() {
      this.geometryPickerOpen = false;
      this.layersPickerOpen = !this.layersPickerOpen;
      if (this.layersPickerOpen === true)
        this.$nextTick(() => this.$refs.layersPicker.focus());
    },
    insightSortingCancelled() {
      this.insightSorterVisible = false;
    },
    insightsSorted(sortedList) {
      this.insightSorterVisible = false;

      for (let item of sortedList) {
        this.currentInsights.find(insight => insight.id == item.id).sort_order = item.sort_order;
      }

      this.saveInsightOrder();
    },
    async saveInsightOrder() {
      let saveCalls = [];
      try {
        for (let insight of this.currentInsights) {
          let saveCall = projectApiService.updateInsight(Number(this.$route.params.id), Number(this.$route.params.study), insight);
          saveCalls.push(saveCall);
        } 
        await Promise.all(saveCalls);
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Insight sort order saved.'
        });
      } catch (ex) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to save insight order.  Please try again.'
        });
      }
    },
    setCurrentInsightIndex(i) {
      // turn off various edit modes when changing insights
      this.$store.dispatch('project/setAnnotationsCompositionMode', false);
      this.$store.dispatch('project/setEditInsightMode', false);
      this.currentInsightIndex = i;
    },
    editInsight() {
      this.geometryPickerOpen = false;
      this.insightCopyBeforeEdit = _.cloneDeep(this.currentInsight);
      this.$store.dispatch('project/setEditInsightMode', true);
      this.$store.dispatch('project/setCreateInsightMode', false);
    },
    createNewInsight() {
      this.newInsightTitle = null;
      this.newInsightDescription = null;
      this.setCreateInsightStep(0);
      this.$store.dispatch('project/setAnnotationsCompositionMode', false);
      this.$store.dispatch('project/setCreateInsightMode', true);
      this.$store.dispatch('project/setEditInsightMode', false);
    },
    toggleInsightSorter() {
      this.insightSorterVisible = !this.insightSorterVisible;
    },
    async exportInsightsPdf() {
      this.$store.dispatch('project/setCreatingInsightPDF', true);
    },
    async deleteCurrentInsight() {
      this.$emit('removingAllLayers', true);

      this.$bvModal.hide('confirm-insight-delete');
                  
      if (this.currentInsight?.pins) {
        for (let pin of this.currentInsight.pins) {
          this.$refs.primaryViewer?.removeAnnotationPin(pin);
        }
      }

      await this.$store.dispatch('project/deleteInsight', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id
      });
      
      this.goToPreviousInsightIfExists();
      this.$store.dispatch('project/setEditInsightMode', false);
    },
    goToPreviousInsightIfExists() {
      if(this.currentInsightIndex > 0)
        this.currentInsightIndex = this.currentInsightIndex - 1;
    },
    finishedUpdatingInsight() {
      this.updatingInsightResource = false;
    },
    goToLastInsight() {
      let lastIndex = this.currentInsights.length - 1;
      this.currentInsightIndex = lastIndex;
    },
    goToLastInsightIfIndexTooLarge() {
      if (this.currentInsightIndex > this.currentInsights.length - 1) {
        this.goToLastInsight();
      }
    },
    async saveInsight() {
      this.layersPickerOpen = false;

      this.geometryPickerOpen = false;
      this.updatingInsightResource = true;
      this.$root.$emit('saveInsight', this.currentInsight, true);
    },
    showInsightLayers(insightLayers) {
      var toggleOff = [].concat(this.layerSets);
      this.insightLayerSets = insightLayers;  //set the InsightsLayerSets collection (which is displayed in the layer panel) to the set of InsightLayers pulled from the store and calcuated property

      this.toggle(this.insightLayerSets, toggleOff); //turn on the new layers and turn off any normal result set layers that might be active
    },
    setCurrentInsightScenario() {
      if(this.viewerMode == 'Insights' && this.currentInsightIsSceneView) {

        let newSceneView = this.currentInsight.sceneview_set[0];
        let simulation = this.selectedStudy.simulation_labels.find(sim => sim.configurationId == newSceneView.configuration_id);
        this.setSelectedInsightConfigurationId(simulation.configurationId);

        // if(this.selectedScenario != scenario.label) {
        //   this.setScenario(scenario.label, scenario.configurationId);
        // }
      }
    },

    //are there any layers of a specific layer_type ('e.g. horizontal) loading?
    resultLayerOfTypeLoading(layerType) {
      return this.layerSets.filter(layer => layer.layerType === layerType).some(layer => layer.isLoading);
    },
    resizeInsightText() {
      let insightDesc = this.$refs.insight_description;
      let insightAnnotations = this.$refs.insight_annotations;
      if(insightDesc && insightAnnotations) {
        let insightDescHeight = insightDesc.clientHeight;
        let insightAnnotationsHeight = insightAnnotations.clientHeight;
        if(insightDescHeight >= 180 && insightAnnotationsHeight >= 180) {
          insightDesc.style.maxHeight = '11.25rem';
          insightAnnotations.style.maxHeight = '11.25rem';
        } else if(insightDescHeight >= 180 && insightAnnotationsHeight < 180) {
          insightDesc.style.maxHeight = `${180 + (180 - insightAnnotationsHeight)}rem`;
        } else if(insightDescHeight < 180 && insightAnnotationsHeight >= 180) {
          insightAnnotations.style.maxHeight = `${180 + (180 - insightDescHeight)}rem`;
        }
      } else if (insightDesc) {
        insightDesc.style.maxHeight = '22.5rem';
      }
    },
    isInsightDraft(insight) {
      if (insight.insight_status=='Draft') {
        return true;
      }
      return false;
    },
    ...mapActions({
      setAddedLayerSets: 'project/simulationAsset/setAddedLayerSets',  //informs the store what assets are currently on display in the layer panel
      setSelectedInsightConfigurationId: 'project/simulationAsset/setSelectedInsightConfigurationId',
      setAbortLoadFlag: 'project/viewer/setAbortLoadFlag',
      setCreateInsightStep: 'project/setCreateInsightStep',
      toggleLayers: 'project/viewer/toggleLayers',  //sends an event to the viewer to turn a set of layers on or off
      setDrawingMode: 'project/setDrawingMode'
    })
  },
  watch: { 
    $route (to){
      if (to.query.page)
        this.currentInsightIndex = to.query.page;
    },
    editInsightMode(newValue) { 
      if (this.currentInsight && newValue) {
        this.$store.dispatch('project/setInsightTypeSelected', this.currentInsight.insight_type);
      }

      if(!newValue && this.annotationsCompositionMode) {
        this.$store.dispatch('project/setAnnotationsCompositionMode', false);
      }
    },
    async sceneViewCompositionMode(newValue) {
      if (!newValue) {
        await this.$nextTick();
        this.setCurrentInsightScenario();
        await this.$nextTick();
        this.resizeInsightText();
        setTimeout(() => {
          this.toggle(this.insightGeometryLayerSets,this.geometryLayerSets);
        }, 50);
        setTimeout(() => {
          this.toggle(this.insightLayers, this.addedLayerSets);
        }, 50);
      }
    },
    /* When the ViewerMode changes, toggle the InsightLayers and result layers accordingly */
    async viewerMode(newValue, oldValue) {
      this.geometryPickerOpen = false;
      this.layersPickerOpen = false;
      if(oldValue == 'Insights') {
        this.setAbortLoadFlag(true);
        this.$store.dispatch('project/setEditInsightMode', false);
        this.$store.dispatch('project/setSceneViewCompositionMode', false);
      }

      if (newValue === 'Insights') {
        //this.setAbortLoadFlag(true);
        await this.$nextTick();
        this.resizeInsightText();
        this.setCurrentInsightScenario();
        this.showInsightLayers(this.insightLayerSets);
        await this.$nextTick();
        this.toggle(this.insightGeometryLayerSets,null);
      }

      if (newValue == 'Viewer') {
        this.toggle(null,this.insightLayerSets.concat(this.insightGeometryLayerSets));
      }
    },
    firstSimulationFinishedLoading(newValue) {
      if (this.viewerMode === 'Insights' && newValue) {
        if (this.currentInsightIsSceneView) {
          if (this.currentInsight.sceneview_set[0].configuration_id == newValue) {
            this.setCurrentInsightScenario();
          }
        }
      } 
    },
    simulationsFinishedLoading(newValue) {
      if (this.viewerMode === 'Insights' && newValue) {
        //this.setAbortLoadFlag(true);
        //this.showInsightLayers(this.insightLayerSets);
        this.setCurrentInsightScenario();
      }
    },
    /* when the insight changes it's associated layers change.  While the currentInsightIndex also tells is the Insight has changed, it doesn't work
    on first load as the layer sets associated with the Insights haven't loaded from the API be the time that the currentInsightIndex watch is hit */
    insightLayers(newValue) {
      //only toggle the viewer if it's in Insights mode.  This prevents multiple layer sets from loading at start-up
      if (this.viewerMode === 'Insights' && this.simulationsFinishedLoading) {
        //this.setAbortLoadFlag(true); // it was interrupting the insight layers to load sometimes 
        this.showInsightLayers(newValue);
      } else {
        //just update the Insights Layer Set collection but don't toggle anything
        this.insightLayerSets = newValue;
      }
    },
    //make sure, as the insight layers change, that any old layers have been turned off
    insightLayerSets(newValue, oldValue) {
      if(this.viewerMode === 'Insights'){
        let toggle_on = [];
        let toggle_off = [];
      
        for (let layer of newValue) {
          if (!oldValue.map(x => x.identifier).includes(layer.identifier)) {
            toggle_on.push(layer);
          }
        }

        for (let layer of oldValue) {
          if(!newValue.map(x => x.identifier).includes(layer.identifier)) {
            toggle_off.push(layer);
          }
        }
      
        this.toggle(toggle_on, toggle_off);
      }
    },
    /* Triggered when the selected configuration changes (including first load).  When the insightGeometryLayerSets (in the store) changes, set the geometryLayers list, which is iterated over in the template to list out the geometry asset layers that
    can be toggle on.  Similar to above, this is watched instead of the layer set collection to ensure the code is only triggered when the underlying assets change, not when the global filter changes */
    insightGeometryAssets() {
      if (this.viewerMode === 'Insights') {
        this.loadDefaultOrSavedGeometryLayers(this.insightGeometryLayerSets);  //put the geometry layers into the layers panel
      }
    },
    async currentInsightIndex(newValue) {

      this.$store.dispatch('project/switchInsight', newValue);
      if(this.viewerMode === 'Insights') {
        await this.$nextTick();
        this.resizeInsightText();
        if (this.simulationsFinishedLoading) {
          this.setCurrentInsightScenario();  //this wont' work for first load but it's called in the watch for simulationsFinishedLoading
        }

        await this.$nextTick();
        //result layers are toggled by the watch on InsightLayerSets but the geometry layers get toggled here.
        if (this.currentInsightIsSceneView) {
          this.toggle(this.insightGeometryLayerSets,null);
        } else {
          this.toggle(null,this.insightGeometryLayerSets);
        }

        if (this.currentInsightIsReportView) {
          this.setDrawingMode(false);
        }
      }
    },
    newInsightTitle(newValue) {
      if (newValue) {
        this.$store.dispatch('project/setNewInsightTitle', newValue);
      }
    },
    newInsightDescription(newValue) {
      if (newValue) {
        this.$store.dispatch('project/setNewInsightDesc', newValue);
      }
    }
  }
};
</script>

<style scoped>
.no-available {
  text-align: center;
  line-height: 200px;
}

.layers-picker {
  width: 60%;
  height: 25%;
  top: 3%;
  left: 39%;
  border-radius: 5px;
  box-shadow: 0px 0px 24px 0px #00000040;
  position: absolute;
  background-color: #F7F8F9;
  overflow-y: auto;
  z-index: 2;
}

.layers-group {
  padding: 5%;
  font-size: 0.8em;
}

.insight-nav-btn-disabled {
  color: var(--grey-500);
  margin-top: 0;
  padding-right: 5px;
}
.insight-nav-btns {
  margin-top: 0;
}
.insight-nav-btn {
  margin-top: 0;
  cursor: pointer;
  padding-right: 5px;
}

button {
  padding: 0;
  font-size: 0.75em;
  font-weight: 700;
  margin: 0;
}

p {
  color: var(--grey-900);
  font: normal normal normal 0.875rem/1.063rem Inter;
}

.dd {
  width: 7.3rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h2 {
  font: normal normal bold 0.688rem/0.875rem Inter;
  text-transform: uppercase;
  color: var(--grey-700);
}

h3 {
  margin-top: 0.625rem;
  font: normal normal 500 0.75rem/0.938rem Inter;
  color: var(--grey-700);
}

.insight-selection-container {
  background-color: var(--grey-400);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  height: 2.375rem;
}

.insight {
  margin-left: 0.75rem;
  margin-top: 0;
}

.insight-dropdown {
  height: 2.375rem;
}

.insight-title-bar-button {
  width: 1.5rem;
  min-width: 1.5rem;
  cursor: pointer;
}

.delete-insight-container {
  margin-top: 0;
  clear: both;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 5%;
}

.gray-button {
  background-color: var(--grey-500);
}

.gray-button:hover {
  background-color: var(--grey-500);
}

.gray-button:focus {
  background-color: var(--grey-500);
}

.gray-button:active {
  background-color: var(--grey-500);
}

.btn-group {
  margin-top: 0;
}

.insight-dropdown /deep/ .dropdown-menu {
  overflow-y: scroll;
  max-height: 60vh;
}

.insight-dropdown-item /deep/ .dropdown-item {
  padding-left: 0.3rem !important;
}

.insight-dropdown-item /deep/ .dropdown-item svg {
  margin-right: -0.3rem !important;
}


.btn-side-paddings {
  padding:0 0.5rem 0 0.5rem;
}
</style>